import React, { useEffect, useState } from 'react';
import useDrivePicker from 'react-google-drive-picker';
import { driveClientId, driveDevelopmentKey } from '../../../../../environment';
import strings from '../../../../../utils/strings.json';
import GoogleDriveComponent from './googleDrive';
import { isValidFilesize } from '../helper';

const GoogleDrive = (props) => {
  const { setUploadInProgress, setSelectedFiles, onDismiss, uploadFileHandler, setLimitExtendMessage } = props;

  const [openPicker, authResponse] = useDrivePicker();
  const [selectedFile, setSelectedFile] = useState(null);
  const [googleAccessToken, setGoogleAccessToken] = useState();
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (authResponse?.access_token) {
      setGoogleAccessToken(authResponse.access_token);
    }
  }, [authResponse]);

  useEffect(() => {
    const handleUploadFile = async (file, downloadLink) => {
      await uploadFileHandler({
        file,
        downloadLink,
        fetchHeaders: { Authorization: `Bearer ${googleAccessToken}` }
      });
    };

    const uploadFilesSequentially = async () => {
      if (!isUploading && selectedFile && selectedFile.length > 0) {
        setIsUploading(true);
        try {
          for (const file of selectedFile) {
            await handleUploadFile(file, file.downloadLink);
          }
        } catch (error) {
          console.error('Error uploading files:', error);
        } finally {
          setIsUploading(false);
          setSelectedFile(null);
        }
      }
    };

    if (authResponse?.access_token && selectedFile && googleAccessToken && !isUploading) {
      uploadFilesSequentially();
    }
  }, [authResponse?.access_token, googleAccessToken, selectedFile, uploadFileHandler, isUploading]);

  const handleOpenPicker = () => {
    openPicker({
      clientId: driveClientId?.replace(/['",]/g, ''),
      developerKey: driveDevelopmentKey?.replace(/['",]/g, ''),
      viewId: 'DOCS',
      showUploadView: false,
      showUploadFolders: true,
      supportDrives: true,
      token: googleAccessToken,
      multiselect: true,
      callbackFunction: (data) => {
        if (data && data.action === 'cancel') {
          setUploadInProgress(false);
          return;
        }
        if (data && data.docs && data.docs.length > 0) {
          setUploadInProgress(true);
          const newFiles =  isValidFilesize(data.docs);
          setSelectedFiles((prevFiles) => {
            const totalFiles = prevFiles.length + newFiles.length;

            if (totalFiles > 20) {
              setLimitExtendMessage(strings.FILE_LIMIT_ERROR_MESSSGE);
              const availableSlots = 20 - prevFiles.length;
              return [...prevFiles, ...newFiles.slice(0, availableSlots)];
            }

            setLimitExtendMessage('');
            return [...prevFiles, ...newFiles];
          });
          const files = data.docs.map((file) => {
            const downloadLink = `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`;
            return { ...file, downloadLink };
          });
          setSelectedFile(files); // Set multiple files
          setUploadInProgress(false);
        }
      }
    });
  };

  return <GoogleDriveComponent handleOpenPicker={handleOpenPicker} onDismiss={onDismiss} />;
};

export default GoogleDrive;
