import { io } from "socket.io-client";
import { socket_listen_key } from "./environment";

const SOCKET_URL = socket_listen_key;
let socket = null;

export const connectSocket = () => {
  if (!socket) {
    socket = io(SOCKET_URL, {
      transports: ['websocket', "polling"],
      reconnection: true,  // Enable reconnection
      reconnectionAttempts: 3,  // Max 3 reconnection attempts
      reconnectionDelay: 1000,  // Delay of 1 second between reconnections
      reconnectionDelayMax: 5000,  // Max reconnection delay 
    });

    socket.on("connect", () => {
      console.log("Socket connected");
    });

    socket.on("connect_error", (err) => {
      console.error("Socket connection error:", err);
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });
  }
  return socket;
};