import React, { useState } from 'react';
import ModalListingComponent from './modalListing';
import { connect, useSelector } from 'react-redux';
import { categories } from '../../../utils/commonUtils';
import { multipleDownload, reconvertFiles } from '../../../redux/slice';
import Notify from '../../../components/common/notify';
import { notification } from 'antd';
import { triggerDownload } from '../../../components/partials/homeComponent/filesConvert/helper';
import { connectSocket } from '../../../socket';

const socket = connectSocket();

const ModalListing = (props) => {
  const {
    selectedFiles,
    setSelectedFiles,
    onCloseConvertPopUp,
    setSelectedRowKeys,
    isShowConvertPopup,
    setProcessing,
    callReconvertFile,
    onFileMetadata,
    metadata,
    isLoading,
    loading,
    processing,
    setIsListingUpdate,
    callMultipleDownload
  } = props;
  const [fileErrors, setFileErrors] = useState({});
  const [modalConvertOptions, setModalConvertOptions] = useState({});
  const [modalOpenDropdowns, setModalOpenDropdowns] = useState({});
  const [convertFormat, setConvertFormat] = useState(null);
  const [convertAllChecked, setConvertAllChecked] = useState(false);
  const [isConversionStart, setIsConversionStart] = useState(false)
  const authenticate = useSelector((state) => state?.authenticate);
  const { _id } = authenticate?.getAuthenticateData?.data || '';
  const allFilesSameFormat = () => {
    if (selectedFiles.length === 0) {
      return true;
    }
    const firstFileCategory = Object.keys(categories).find((category) =>
      categories[category].includes(selectedFiles[0].convertForm)
    );

    if (!firstFileCategory) {
      return false;
    }
    return selectedFiles.every((file) => categories[firstFileCategory].includes(file.convertForm));
  };

  const handleConvertAllChange = (e) => {
    const isChecked = e.target.checked;
    setConvertAllChecked(isChecked);
    if (isChecked && convertFormat) {
      setSelectedFiles((prevFiles) =>
        prevFiles.map((file) => ({
          ...file,
          format: convertFormat
        }))
      );
      setModalConvertOptions(() => {
        const updatedOptions = {};
        selectedFiles.forEach((file) => {
          updatedOptions[file.key] = convertFormat.toLowerCase();
        });
        return updatedOptions;
      });
      setFileErrors({});
    } else {
      setSelectedFiles((prevFiles) =>
        prevFiles.map((file) => ({
          ...file,
          format: null
        }))
      );
      setModalConvertOptions(() => {
        const updatedOptions = {};
        selectedFiles.forEach((file) => {
          updatedOptions[file.key] = null;
        });
        return updatedOptions;
      });
      // setConvertFormat('');
    }
  };
  const handleOnClose=()=>{
    setConvertAllChecked(false);
    onCloseConvertPopUp(false);
    setSelectedRowKeys([]);
    setModalConvertOptions({});
    setIsConversionStart(false);
    setProcessing({});
    setIsListingUpdate(true);
    
  }
  const handleBatchConvert = async () => {
    const filesToConvert = selectedFiles.map((file) => ({
      id: file.key,
      convertTo: modalConvertOptions[file.key],
    }));
   // onCloseConvertPopUp(false);
      // setSelectedRowKeys([]);
      // setSelectedFiles([]);
      // setFileErrors({});
    setIsConversionStart(true); // Start the simulation
  
    const initialProgress = filesToConvert.reduce((acc, file) => {
      acc[file.id] = { percent: 10, status: "active" };
      return acc;
    }, {});
  
    setProcessing(initialProgress);
  
    try {
      socket.emit("join", _id);
      const response = await callReconvertFile(filesToConvert);  
      if (response.type === 'file/reconvertFiles/fulfilled') {
        setConvertAllChecked(false);
        setIsListingUpdate(true);
        socket.on("notification", (data) => {
          console.log("notification", data);
        })
        socket.on("status-update", (data) => {
          console.log("status-update", data);
          const { conversionStatus, parentId, convertedFileType } = data.data;
          setProcessing((prev) => {
            const updatedProcessing = { ...prev };
             if (filesToConvert.find((file) => file.id === parentId)) {
              updatedProcessing[filesToConvert.find((file) => file.id === parentId).id] = {
                percent: 100,
                status: conversionStatus === "SUCCESS" ? "success" : "error",
                convertTo: convertedFileType || filesToConvert.find((file) => file.id === parentId).convertTo,
                fileUrl: data.data.exportFileUrl
              };
            }
  
            return updatedProcessing;
          });
        });
      }
    } catch (error) {
      console.error("Batch conversion error:", error);
    }
  };
  
  const toggleModalDropdown = (key) => {
    setModalOpenDropdowns((prevState) => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  const handleSelectModalConvertTo = (fileId, convertToOption) => {
    console.log("convertToOption",convertToOption)
    if (allFilesSameFormat()) {
      setConvertFormat(convertToOption);
    }
    if (fileId === 1) {
      setSelectedFiles((prevFiles) =>
        prevFiles.map((file) => ({
          ...file,
          format: convertToOption
        }))
      );
      setModalConvertOptions(() => {
        const updatedOptions = {};
        selectedFiles.forEach((file) => {
          updatedOptions[file.key] = convertToOption.toLowerCase();
        });
        return updatedOptions;
      });
      setModalConvertOptions((prevOptions) => ({
        ...prevOptions,
        [fileId]: convertToOption.toLowerCase()
      }));
      setFileErrors({});
    } else {
      setModalConvertOptions((prevOptions) => ({
        ...prevOptions,
        [fileId]: convertToOption?.toLowerCase()
      }));
      setFileErrors((prev) => {
        const updatedErrors = { ...prev };
        delete updatedErrors[fileId];
        return updatedErrors;
      });
    }
    toggleModalDropdown(fileId);
  };

  const handleDeleteSelectedFiles = (deleteFile) => {
    setSelectedFiles((prevSelectedFiles) => {
      const updatedFiles = prevSelectedFiles.filter((file) => file.key !== deleteFile);
      if (updatedFiles.length === 0) {
        onCloseConvertPopUp(false);
      }
      return updatedFiles;
    });

    setSelectedRowKeys((prevSelectedKeys) => prevSelectedKeys.filter((key) => key !== deleteFile));
  };
  const downloadZip = async (data) => {
    const fileIds = data.map((file) => file.key);
    const requestData = { filesId: fileIds };
    socket.emit('join', _id);
    socket.off('zip-status');
    const result = await callMultipleDownload(requestData);
    const { type, payload } = result;

    if (type === 'multipleDownload/fulfilled') {
      if (payload.success) {
        triggerDownload(payload.data.fileUrl);
        Notify('success', "strings.DOWNLOAD_SUCCESS_MSG", '');
      } else {
        Notify('success', 'We will notify after converting in zip', '');
        socket.on('zip-status', (data) => {
          if (!data.data.processing) {
            if (data.data.status === 'SUCCESS') {
              notification.success({
                message: 'Success! Your file has been zip and it ready for download.',
                placement: 'topRight',
                duration: 5
              });
              const fileUrl = data.data.fileUrl;
              if (fileUrl) {
                triggerDownload(fileUrl);
              } else {
                console.error('File URL not found in the response data.');
              }
            } else {
              notification.error({
                message: 'Oops! There was an error downloading your files. Please try again ',
                placement: 'topRight',
                duration: 5
              });
            }
          }
        });
      }
    } else {
      Notify('error', 'Selected files failed to download', '');
    }
  };
  return (
    <ModalListingComponent
      handleBatchConvert={handleBatchConvert}
      selectedFiles={selectedFiles}
      onCloseConvertPopUp={onCloseConvertPopUp}
      isShowConvertPopup={isShowConvertPopup}
      modalConvertOptions={modalConvertOptions}
      toggleModalDropdown={toggleModalDropdown}
      onFileMetadata={onFileMetadata}
      modalOpenDropdowns={modalOpenDropdowns}
      convertAllChecked={convertAllChecked}
      convertFormat={convertFormat}
      handleSelectModalConvertTo={handleSelectModalConvertTo}
      handleConvertAllChange={handleConvertAllChange}
      allFilesSameFormat={allFilesSameFormat}
      metadata={metadata}
      isLoading={isLoading}
      loading={loading}
      fileErrors={fileErrors}
      handleDeleteSelectedFiles={handleDeleteSelectedFiles}
      processing={processing}
      handleOnClose={handleOnClose}
      isConversionStart={isConversionStart}
      setProcessing={setProcessing}
      downloadZip={downloadZip}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.files.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  callReconvertFile: (data) => dispatch(reconvertFiles(data)),
  callMultipleDownload: (data) => dispatch(multipleDownload(data)),
  
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalListing);
