import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import Notify from '../../../components/common/notify';
import strings from '../../../utils/strings.json';
import DashFileListingComponent from './dashListing';
import { notification } from 'antd';
import { fetchFiles, multipleDownload, reconvertFiles, removeFile, retryUploadFile } from '../../../redux/slice';
import PostUploadModal from '../../../components/modals/postUploadModal/postUploadModal';
import { triggerDownload } from '../../../components/partials/homeComponent/filesConvert/helper';
import { scrollToTop } from '../../../utils/commonUtils';
import { useLocation, useNavigate } from 'react-router-dom';
import { connectSocket } from '../../../socket';

const socket = connectSocket();

const DashFileListing = (props) => {
  const {
    data,
    loading,
    fetchFiles,
    metadata,
    callRemoveFile,
    callReconvertFile,
    error,
    selectedFiles,
    setSelectedFiles,
    onCloseConvertPopUp,
    onFileMetadata,
    isLoading,
    setProcessing,
    selectedRowKeys,
    setSelectedRowKeys,
    processing,
    callMultipleDownload,
    authenticate,
    callRetryUploadFile,
    isListingUpdate,
    setIsListingUpdate
  } = props;

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({});
  const [isConvert, setIsConvert] = useState(false);
  const [search, setSearch] = useState();
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [convertOptions, setConvertOptions] = useState();
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [fileConvertStatus, setFileConvertStatus] = useState('');
  const [convertLoading, setConvertLoading] = useState('');
  const location = useLocation();
  const { convertFilesPending } = location.state || {};
  const { _id } = authenticate?.getAuthenticateData?.data || '';
  const limit = 10;
  const navigate = useNavigate();
  const handleSearch = (searchValue) => {
    let page = 1;
    let limit = 10;
    fetchFiles({ page, search:searchValue, limit });
    setSearch(searchValue);
  };
  useEffect(() => {
    if (_id) {
      const notificationData = (data) => {
        if (data.data.status === 'SUCCESS') {
          setConvertLoading(false);
        } else {
          notification.error({
            message: data?.data?.message,
            placement: 'topRight',
            duration: 5
          });
        }
      };
      socket.on('notification', notificationData);

      return () => {
        socket.off('notification', notificationData);
      };
    }
  }, [_id]);

  const handleResetState = useCallback(() => {
    navigate(location.pathname, { state: { ...location.state, convertFilesPending: false } });
  }, [navigate, location.pathname, location.state]);

  useEffect(() => {
    localStorage.removeItem('disable');
    if (convertFilesPending) {
      setConvertLoading(true);
      setFileConvertStatus("Your files are being processed. We'll notify you once the conversion is complete!");
      setTimeout(() => {
        setFileConvertStatus('');
        handleResetState();
        setConvertLoading(false);
      }, 5000);
    }
  }, [convertFilesPending, handleResetState]);

  useEffect(() => {
    if(!search){
      fetchFiles({ page, limit, sort });
    }
    setIsConvert(false);
    setIsListingUpdate(false);
  }, [page, limit, sort, fetchFiles, search, isConvert, isListingUpdate, setIsListingUpdate]);

  const handleSortChange = (field) => {
    setSort((prevSort) => ({ [field]: prevSort[field] === 1 ? -1 : 1 }));
    setPage(1);
  };

  const paginationProps = {
    current: page,
    pageSize: limit,
    total: data?.data?.metaData?.total || 0,
    onChange: (page) => setPage(page)
  };

  const handleDeleteFile = async (deleteFiles) => {
    const deleteFilesResult = await callRemoveFile(deleteFiles);
    const { type, error } = deleteFilesResult;
    if (type === 'file/remove/fulfilled') {
      Notify('success', strings.DELETE_FILE_SUCCESS_MSG, '');
      fetchFiles({ page, limit, sort, search });
    } else {
      Notify('error', error?.message, '');
    }
  };

  const handleReconvert = () => {
    if (selectedFiles.length > 0) {
      onCloseConvertPopUp(true);
    } else {
      Notify('error', strings.NO_FILE_SELECTED, '');
    }
  };

  const onDownload = async (urls) => {
    if (selectedFiles.length > 1) {
      const fileIds = selectedFiles.map((file) => file.key);
      const requestData = { filesId: fileIds };
      socket.emit('join', _id);
      socket.off('zip-status');
      const result = await callMultipleDownload(requestData);
      const { type, payload } = result;

      if (type === 'multipleDownload/fulfilled') {
        if (payload.success) {
          triggerDownload(payload.data.fileUrl);
          Notify('success', strings.DOWNLOAD_SUCCESS_MSG, '');
        } else {
          Notify('success', 'We will notify after converting in zip', '');
          socket.on('zip-status', (data) => {
            if (!data.data.processing) {
              if (data.data.status === 'SUCCESS') {
                notification.success({
                  message: 'Success! Your file has been zip and it ready for download.',
                  placement: 'topRight',
                  duration: 5
                });
                const fileUrl = data.data.fileUrl;
                if (fileUrl) {
                  triggerDownload(fileUrl);
                } else {
                  console.error('File URL not found in the response data.');
                }
              } else {
                notification.success({
                  message: 'Oops! There was an error downloading your files. Please try again ',
                  placement: 'topRight',
                  duration: 5
                });
              }
            }
          });
        }
      } else {
        Notify('error', 'Selected files failed to download', '');
      }
    } else if (selectedFiles.length > 0) {
      const fileUrl = selectedFiles.map((file) => file.exportFileUrl);
      triggerDownload(fileUrl);
      Notify('success', strings.DOWNLOAD_SUCCESS_MSG, '');
    } else if (urls && urls.length > 0) {
      urls.forEach((url) => {
        triggerDownload(url);
        Notify('success', strings.DOWNLOAD_SUCCESS_MSG, '');
      });
    } else {
      Notify('error', strings.FILE_SELECT_ERROR, '');
    }
    setSelectedRowKeys([]);
    setSelectedFiles([]);
  };

  const handleDeleteSelectedFiles = (deleteFile) => {
    if (selectedFiles.length > 0) {
      const fileIds = selectedFiles.map((file) => file.key);
      handleDeleteFile(fileIds);
      setSelectedFiles((prevSelectedFiles) => {
        const updatedFiles = prevSelectedFiles.filter((file) => !fileIds.includes(file.key));
        return updatedFiles;
      });
    } else if (deleteFile) {
      handleDeleteFile(deleteFile);
    } else {
      Notify('error', strings.NO_FILE_SELECTED, '');
    }
  };

  const handleConvert = async (fileId) => {
    try {
      setProcessing((prev) => ({ ...prev, [fileId]: true }));
      const fileConversions = [{ id: fileId, convertTo: convertOptions[fileId] }];
      const response = await callReconvertFile(fileConversions);
      if (response.type === 'file/reconvertFiles/fulfilled') {
        setConvertOptions((prev) => {
          const updatedOptions = { ...prev };
          delete updatedOptions[fileId];
          return updatedOptions;
        });
      }
    } catch (error) {
      console.error('Error during reconversion:', error);
    } finally {
      setProcessing((prev) => ({ ...prev, [fileId]: false }));
    }
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [fileId]: false
    }));
  };

  const toggleDropdown = (fileName) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [fileName]: !prevState[fileName]
    }));
  };

  const handleSelectConvertTo = (fileId, convertToOption) => {
    setConvertOptions((prevOptions) => ({
      ...prevOptions,
      [fileId]: convertToOption?.toLowerCase()
    }));
    toggleDropdown(fileId);
  };

  const handleRetryFile = async (fileId) => {
    try {
      const requestData = { fileId };
      const result = await callRetryUploadFile(requestData);
      if (result.type === 'retryUploadFile/fulfilled') {
        setFileConvertStatus(
          "Your files are being processed. We'll notify you once the conversion is complete!",
          fileId
        );
        scrollToTop();
        setTimeout(() => {
          setFileConvertStatus('');
        }, 5000);
      } else {
        Notify('error', result.message, '');
      }
    } catch (error) {
      Notify('error', error.message, '');
    }
  };
  return (
    <>
      <DashFileListingComponent
        data={data}
        metadata={metadata}
        isLoading={isLoading}
        loading={loading}
        paginationProps={paginationProps}
        onSortChange={handleSortChange}
        setSelectedFiles={setSelectedFiles}
        selectedFiles={selectedFiles}
        onSearch={handleSearch}
        search={search}
        error={error}
        onFileMetadata={onFileMetadata}
        processing={processing}
        setSelectedRowKeys={setSelectedRowKeys}
        selectedRowKeys={selectedRowKeys}
        handleSelectConvertTo={handleSelectConvertTo}
        handleConvert={handleConvert}
        handleDeleteSelectedFiles={handleDeleteSelectedFiles}
        handleReconvert={handleReconvert}
        onDownload={onDownload}
        convertOptions={convertOptions}
        openDropdowns={openDropdowns}
        toggleDropdown={toggleDropdown}
        setIsUploadModal={setIsUploadModal}
        handleRetryFile={handleRetryFile}
        fileConvertStatus={fileConvertStatus}
        convertFilesPending={convertFilesPending}
        convertLoading={convertLoading}
        setIsListingUpdate={setIsListingUpdate}
        isListingUpdate={isListingUpdate}
      />
      {isUploadModal && (
        <PostUploadModal
          visible={isUploadModal}
          onClose={setIsUploadModal}
          isLoading={isLoading}
          setIsListingUpdate={setIsListingUpdate}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.files.data,
    loading: state.files.loading,
    error: state.files.error,
    metadata: state.metaFiles.data,
    authenticate: state.authenticate
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchFiles: (params) => dispatch(fetchFiles(params)),
  callRemoveFile: (params) => dispatch(removeFile(params)),
  callReconvertFile: (data) => dispatch(reconvertFiles(data)),
  callMultipleDownload: (data) => dispatch(multipleDownload(data)),
  callRetryUploadFile: (data) => dispatch(retryUploadFile(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(DashFileListing);
