import React from 'react';
import {
  Cancle_button,
  down_arrows,
  download_button_icon,
  downloadSuccessIcon,
  format_icon,
  right_arrows
} from '../../../assets/images/dashboard';
import Modal from '../../../components/common/modal/modal';
import { ConverterDropdown } from '../../../components/partials/homeComponent/filesConvert/converterDropdown';
import strings from '../../../utils/strings.json';
import { FaExclamationTriangle } from 'react-icons/fa';
import { capitalizeFirstLetter } from '../../../utils/commonUtils';
import FileIcon from '../../../components/fileIcon/fileIcon';
import './modalListing.scss';
import { Button, Progress } from 'antd';
import { triggerDownload } from '../../../components/partials/homeComponent/filesConvert/helper';


const ModalListingComponent = (props) => {
  const {
    handleBatchConvert,
    isShowConvertPopup,
    // onCloseConvertPopUp,
    selectedFiles,
    modalConvertOptions,
    toggleModalDropdown,
    onFileMetadata,
    modalOpenDropdowns,
    handleSelectModalConvertTo,
    allFilesSameFormat,
    metadata,
    isLoading,
    fileErrors,
    handleDeleteSelectedFiles,
    processing,
    handleOnClose,
    isConversionStart,

    downloadZip
  } = props;
  const allFilesConverted = Object.values(processing).some((file) => file?.status === 'success');
  const singleFileDownload = (fileId) => {
    triggerDownload(processing[fileId]?.fileUrl);
  };
  return (
    <Modal
      isOpen={isShowConvertPopup}
      onOk={isShowConvertPopup}
      onClose={handleOnClose}
      footer={null}
      className='modal-content urL-modal'
    >
      <div className='modal-listing-outer'>
        <div className='modal-title'>
          <h2>Files Converter</h2>
        </div>
        <div className='checkbox-container'>
          {allFilesSameFormat() && (
            <>
              <button
                className='convert-to-btn'
                onClick={() => {
                  onFileMetadata('rtf');
                  toggleModalDropdown(1);
                }}
              >
                <img src={format_icon} alt='Format Icon' />
                <span
                  style={{
                    textTransform: modalConvertOptions && modalConvertOptions[1] && 'uppercase'
                  }}
                >
                  {(modalConvertOptions && modalConvertOptions[1]) || 'Convert all to'}
                </span>
                <img src={down_arrows} alt='' />
                {modalOpenDropdowns[1] && (
                  <ConverterDropdown
                    setIsOpen={() => toggleModalDropdown(1)}
                    onSelectConvertTo={(option) => {
                      handleSelectModalConvertTo(1, option);
                      toggleModalDropdown(1);
                    }}
                    metadata={metadata}
                    isLoading={isLoading}
                  />
                )}
              </button>
            </>
          )}
        </div>
        {selectedFiles.slice(0, 20).map(({ name, size, convertedFileType, convertForm, key }) => {
          let status = 'success';
          const progress = processing[key] || { percent: 10, status: 'active' };
          return (
            <>
              <div className='modal_content-wrapper' key={key}>
                <div className='convert-formate-inner'>
                  <div className='file-converting-main'>
                    <div className='file-convert-img'>
                      <FileIcon extension={convertedFileType} />
                    </div>
                    <div className='file-list-inner'>
                      <p>{name}</p>
                      <span>{size}</span>
                      <span
                        className={`errorclass ${status !== strings.SUCCESS && status !== strings.PENDING ? 'error-style' : ''}`}
                      >
                        {status !== strings.SUCCESS && status !== strings.PENDING && (
                          <>
                            <FaExclamationTriangle style={{ color: 'red', marginRight: '4px' }} />
                            {`${capitalizeFirstLetter(status)}`}
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className='file-actions'>
                    <div className='convert-to-main'>
                      {(status === strings.SUCCESS || status === strings.PENDING) && (
                        <div className='convert-formate'>
                          {progress?.convertTo && progress?.status==="active" && (
                            <>
                              <div className='file-convert-img'>
                                <FileIcon extension={progress?.convertTo} />
                              </div>
                              <div className='file-list-inner'>
                                <p>{progress?.convertTo}</p>
                              </div>
                            </>
                          )}
                          <span className={`errorclass ${progress.status === 'error' ? 'error-style' : ''}`}>
                            {progress.status === 'error' && (
                              <>
                                <FaExclamationTriangle style={{ color: 'red', marginRight: '4px' }} />
                                {capitalizeFirstLetter(progress.errorMessage || 'Failed')}
                              </>
                            )}
                          </span>
                          {progress?.status === 'success' && (
                            <div className='convert-formate'>
                              <img src={downloadSuccessIcon} alt='Download Button Icon' height={32} width={32} />
                              <Button className='download-icon' onClick={() => singleFileDownload(key)}>
                                <img src={download_button_icon} alt='Download Button Icon' height={32} width={32} />
                              </Button>
                            </div>
                          )}
                          {!isConversionStart && (
                            <div className='convert-option'>
                              <button
                                className='convert-to-btn'
                                onClick={() => {
                                  onFileMetadata(convertForm);
                                  toggleModalDropdown(key, convertForm);
                                }}
                              >
                                <img src={format_icon} alt='Format Icon' />
                                <span
                                  style={{
                                    textTransform: modalConvertOptions && modalConvertOptions[key] && 'uppercase'
                                  }}
                                >
                                  {(modalConvertOptions && modalConvertOptions[key]) || strings.CONVERT_TO}
                                </span>
                                <img src={down_arrows} alt='' />
                              </button>
                              {fileErrors[key] && <p className='error-message'>{strings.NO_ALL_OPTION_SELECTED}</p>}
                            </div>
                          )}
                        </div>
                      )}

                      {modalOpenDropdowns[key] && (
                        <ConverterDropdown
                          setIsOpen={() => toggleModalDropdown(key)}
                          onSelectConvertTo={(option) => handleSelectModalConvertTo(key, option)}
                          metadata={metadata}
                          isLoading={isLoading}
                        />
                      )}
                      {!isConversionStart && (
                        <div className='convert-close'>
                          <button className='close_imgfield' onClick={() => handleDeleteSelectedFiles(key)}>
                            <img src={Cancle_button} alt='' />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  {isConversionStart && (
                    <div className='progress_status'>                 <Progress
                      percent={progress?.percent}
                      status={
                        progress?.status === 'success'
                          ? 'success'
                          : progress?.status === 'active'
                            ? 'active'
                            : 'exception'
                      }
                      strokeColor={
                        progress?.status === 'success' ? 'green' : progress?.status === 'active' ? 'blue' : 'red'
                      }
                      showInfo={false}
                    />
                    </div>
                  )}
                </div>
              </div>
            </>
          );
        })}
        {!isConversionStart && (
          <div style={{ textAlign: 'center' }}>
            <div className='upload_btn-modal' onClick={handleBatchConvert}>
              <span>
                Convert Files
                <img src={right_arrows} alt='' color='white' height={10} width={20} />
              </span>
            </div>
          </div>
        )}
        {allFilesConverted && (
          <button className='download-all-icon' onClick={() => downloadZip(selectedFiles)}>
            Download file <img src={download_button_icon} alt='Download Button Icon' height={16} width={16} />
          </button>
        )}
      </div>
    </Modal>
  );
};
export default ModalListingComponent;

