import React, { useEffect, useMemo, useState } from 'react';
import './header.scss';
import { header_logo, sign_in_icon, language_icon } from '../../../assets/images/header';
import { useTranslation } from 'react-i18next';
import { HiMenu } from 'react-icons/hi';
import { Dropdown, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../../common/modal/modal';
import { Cookies } from 'react-cookie';
import ReactCountryFlag from 'react-country-flag';
import { languages } from '../../../utils/commonUtils';
const Header = () => {
  const cookie = useMemo(() => new Cookies(), []);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(cookie.get('lang') || 'en');
  const [scrollTarget, setScrollTarget] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isConvertPage = window.location.pathname.split('/')[2] === 'convert';

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (scrollTarget) {
      scrollToSection(scrollTarget);
      setScrollTarget(null);
    }
  }, [scrollTarget]);

  useEffect(() => {
    i18n.changeLanguage(lang);
    // Save language selection to cookies
    cookie.set('lang', lang, {
      path: '/',
      sameSite: true
    });
  }, [lang, i18n, cookie]);

  const handleChangeLanguage = (lang, flagCode) => {
    i18n.changeLanguage(lang);
    if (window.location.pathname.split('/')[2]) {
      navigate(`/${lang}/${window.location.pathname.split('/')[2]}`);
    } else {
      navigate(`/${lang}`);
    }
    setIsModalOpen(false);
    document.body.style.overflow = 'unset';
    setLang(lang);
    cookie.set('lang', lang, {
      path: '/',
      sameSite: true
    });
    cookie.set('langlogo', flagCode, {
      path: '/',
      sameSite: true
    });
  };

  const items = [
    {
      label: (
        <Link to={`/${lang}${isConvertPage ? '/convert' : '/'}`} onClick={() => setScrollTarget('conversion-types')}>
          <div className='mobile-menu-text'>{'Conversion Types'}</div>
        </Link>
      ),
      key: '0'
    },
    {
      label: (
        <Link to={`/${lang}${isConvertPage ? '/convert' : '/'}`} onClick={() => setScrollTarget('how-it-works')}>
          <div className='mobile-menu-text'>{'How It Works'}</div>
        </Link>
      ),
      key: '1'
    },
    {
      label: (
        <Link to={`/${lang}/pricing`}>
          <div className='mobile-menu-text'>{'Pricing'}</div>
        </Link>
      ),
      key: '3'
    },
    !isConvertPage && { 
      label: (
        <Link to={`/${lang}/contact`}>
          <div className='mobile-menu-text'>{'Contact Us'}</div>
        </Link>
      ),
      key: '4'
    },
    !isConvertPage && {
      label: (
        <Link to={`/${lang}/unsubscribe`}>
          <div className='mobile-menu-text'>{'Unsubscribe'}</div>
        </Link>
      ),
      key: '5'
    },
    {
      label: (
        <div className='login-btn login-btn-show'>
          <Link to={`/${lang}/login`}>
            <img src={sign_in_icon} alt='login icon' loading='lazy' />
            <span>Log In</span>
          </Link>
        </div>
      ),
      key: '6'
    }
  ];
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <header className='site-header'>
        <div className='main_container'>
          <div className='Header-main'>
            <div className='header-logo'>
              <Link to={`/${lang}/`}>
                <img src={header_logo} alt='main logo' loading='lazy' />
              </Link>
            </div>
            <div className='navigation-menu'>
              <div className='navigation-links'>
                <ul className='link-list'>
                  <li className='link'>
                    <Link
                      to={`/${lang}${isConvertPage ? '/convert' : '/'}`}
                      onClick={() => setScrollTarget('conversion-types')}
                    >
                      Conversion Types
                    </Link>
                  </li>
                  <li className='link'>
                    <Link
                      to={`/${lang}${isConvertPage ? '/convert' : '/'}`}
                      onClick={() => setScrollTarget('how-it-works')}
                    >
                      How It Works
                    </Link>
                  </li>
                  <li className='link'>
                    <Link to={`/${lang}/contact`}>Contact Us</Link>
                  </li>
                  {!isConvertPage && (
                    <>
                      <li className='link'>
                        <Link to={`/${lang}/pricing`}>Pricing</Link>
                      </li>
                      <li className='link'>
                        <Link to={`/${lang}/unsubscribe`}>Unsubscribe</Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
              <div className='header-lang-drop'>
                <li>
                  <img onClick={showModal} src={language_icon} alt='language icon' loading='lazy' />
                </li>
                <Modal title='Language' isOpen={isModalOpen} onClose={handleCancel} className='header-language-modal'>
                  <div className='df-modal-language'>
                    {languages.map((item) => (
                      <div
                        key={item.code}
                        className={`lang ${lang === item.code && 'active'}`}
                        onClick={() => handleChangeLanguage(item.code, item.flagCode)}
                      >
                        <div className='flag'>
                          <ReactCountryFlag
                            countryCode={item.flagCode}
                            svg
                            style={{
                              width: '22px',
                              height: '22px'
                            }}
                          />
                        </div>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </Modal>
              </div>
              <Link to={`/${lang}/login`}>
                <div className='login-btn'>
                  <img src={sign_in_icon} alt='login icon' loading='lazy' />
                  <span>Log In</span>
                </div>
              </Link>
              <div className='mobile-menu-icon'>
                <Dropdown
                  menu={{ items }}
                  trigger={['click']}
                  placement='bottomRight'
                  overlayClassName='mobile-menu-toggle-list'
                >
                  <span onClick={(e) => e.preventDefault()}>
                    <Space>
                      <HiMenu />
                    </Space>
                  </span>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
