import React, { useEffect, useState, useCallback, useMemo } from 'react';
import ConvertFileComponent from './convertFile';
import { connect } from 'react-redux';
import { Cookies, useCookies } from 'react-cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { checkSettingBanIp, iPQualityScore, settingBanIp } from '../../redux/slice';
const ConvertFile = (props) => {
  const { callSettingBanIp, callCheckSettingBanIpSlice, getIpQualityScore, ipData } = props;
  const cookies = useMemo(() => new Cookies(), []);
  const lang = cookies.get('lang');
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const [ip, setIp] = useState();
  const keys = Object.keys(searchNumber);
  // eslint-disable-next-line no-unused-vars
  const [tokens, setTokens, removeToken] = useCookies(['param']);
  const token = cookies.get('token');
  useEffect(() => {
    if (keys.length > 0) {
      cookies.set('param', JSON.stringify(searchNumber), { path: '/' });
    } else {
      removeToken(['param']);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (ipData && ipData.ip) {
      setIp(ipData.ip);
    }
  }, [ipData]);

  const handleIpApiTest = useCallback(async () => {
    try {
      const data = {
        ipAddress: ip
      };
      const IpResult = await getIpQualityScore(data);
      const { type, payload } = IpResult;
      const { active_vpn, is_crawler, ISP } = payload.data.data;
      if (type === 'iPQualityScore/fulfilled') {
        if (IpResult.payload.data.data.ISP === 'iCloud Private Relay' && IpResult.payload.data.data.active_vpn) {
          navigate(`/${lang}/Convert`);
        } else if (active_vpn || is_crawler || ISP.includes('Google') || ISP.includes('google')) {
          cookies.set('banned', 'true');
          const data = {
            ipAddress: ip
          };
          callSettingBanIp(data);
        }
      }
    } catch (error) {
      console.error('Error in handleIpApiTest:', error);
    }
  }, [ip, getIpQualityScore, navigate, lang, cookies, callSettingBanIp]);

  const handleGetIp = useCallback(async () => {
    if (token) {
      navigate(`/${lang}/dash`);
    }
    if (ip) {
      const resData = {
        ipAddress: ip
      };
      const result = await callCheckSettingBanIpSlice(resData);
      const { type } = result;
      if (type === 'checkSettingBanIp/fulfilled') {
        if (keys.includes('clk')) {
          if (searchNumber.clk === 'ae4h3') {
            if (result?.payload?.data) {
              handleIpApiTest();
            }
          } else {
            navigate(`/${lang}/`);
          }
        }
      }
    }
  }, [ip, token, navigate, lang, keys, searchNumber.clk, callCheckSettingBanIpSlice, handleIpApiTest]);

  useEffect(() => {
    handleGetIp();
  }, [handleGetIp]);

  return (
    <div>
      <ConvertFileComponent />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    iPQualityScore: state.iPQualityScore,
    checkSettingBanIp: state.checkSettingBanIp,
    ipData: state.getIPIfy.getIPIfyData
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callSettingBanIp: (data) => dispatch(settingBanIp(data)),
    callCheckSettingBanIpSlice: (data) => dispatch(checkSettingBanIp(data)),
    getiPQualityScore: (data) => dispatch(iPQualityScore(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConvertFile);
