/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import DropboxChooser from 'react-dropbox-chooser';
import { drop_box_image } from '../../../../../assets/images/dashboard';
import { APP_KEY_DROPBOX, dropboxExtensions } from '../../../../../utils/commonUtils';
import strings from '../../../../../utils/strings.json';
import { useSession } from '../../../../../context/SessionContext';
import { handleRemoveFocus } from '../helper';

const DropBoxComponent = forwardRef((props, ref) => {
  const { handleSuccess, onDismiss, uniqueKey = 'Dropbox' } = props;
  const buttonRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const { moduleRef } = useSession();

  // Expose focus to parent via useImperativeHandle
  useImperativeHandle(ref, () => ({
    focus: () => {
      if (buttonRef.current) {
        buttonRef.current.focus();
      }
    }
  }));

  useEffect(() => {
    moduleRef(uniqueKey, buttonRef);
  }, [uniqueKey, buttonRef, moduleRef]);
  const handleDropboxSuccess = (files) => {
    setIsProcessing(false);
    handleSuccess(files);
  };
  const handleDropboxChooser = () => {
    if (!isProcessing && !onDismiss) {
      setIsProcessing(true);
      handleRemoveFocus(buttonRef);
    }
  };

  return (
    <DropboxChooser
      appKey={APP_KEY_DROPBOX}
      success={handleDropboxSuccess}
      cancel={() => setIsProcessing(false)}
      multiselect={true}
      extensions={dropboxExtensions}
      linkType='direct'
      disabled={onDismiss || isProcessing} 
    >
      <div
        className='device-icon-inner'
        style={{ cursor: onDismiss || isProcessing ? 'default' : 'pointer' }}
        ref={buttonRef}
        tabIndex={0}
        onClick={handleDropboxChooser}
      >
        <img src={drop_box_image} alt='Drop Box' />
        <span>{strings.DROPBOX}</span>
      </div>
    </DropboxChooser>
  );
});

export default DropBoxComponent;
