import React, { useEffect, useState } from 'react';

const SelectFilesComponent = (props) => {
  const {  handleDrop, handleFileChange,onDismiss } = props;
  const [uploadText, setUploadText] = useState("Drag & Drop files here to upload");

  useEffect(() => {
    const updateText = () => {
      if (window.innerWidth <= 768) {
        setUploadText("Select your files to get started");
      } else {
        setUploadText("Drag & Drop files here to upload");
      }
    };

    updateText(); // Set initial text based on screen size
    window.addEventListener("resize", updateText);

    return () => window.removeEventListener("resize", updateText);
  }, []);
  return (
    <div className={`drag-drop-element ${onDismiss ? 'no-pointer' : ''}`}>
      <div
        className='drop-area'
        onDrop={onDismiss ? null : handleDrop}
        onDragOver={onDismiss ? null : (event)=>event.preventDefault()}
        style={{
          borderRadius: '5px',
          padding: '20px',
          textAlign: 'center',
          cursor: onDismiss ? 'default' : 'pointer'
        }}
      >
        <input
          type='file'
          id='drag_drop_file'
          multiple
          onChange={handleFileChange}
          disabled={onDismiss}
          style={{ display: 'none' }}
        />
        <label htmlFor='drag_drop_file' className={onDismiss ? 'disable-button' : ''}>
        {uploadText}
        </label>
      </div>
    </div>
  );
};

export default SelectFilesComponent;
