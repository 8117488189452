import React from 'react';
import { Spin } from 'antd';
import Modal from '../../common/modal/modal';
import { FileConvert } from '../../partials/homeComponent';
import './postUploadModal.scss';
const PostUploadModal = (props) => {
  const { visible, onClose, isLoading, setIsListingUpdate } = props;
  const storedValue = localStorage.getItem("disable");
  const  postUploadModalClass = storedValue ? "hide-upload-modal" : "file-convert-modal";
  return (
    <Modal
      isOpen={visible}
      onOk={() => onClose(false)}
      onClose={() => onClose(false)}
      maskClosable={false}
      closeIcon={false}
      className={postUploadModalClass}
    >
      <Spin spinning={isLoading}>
        <FileConvert setIsUploadModal={onClose} isUpdateTitle={true} setIsListingUpdate={setIsListingUpdate}  />
      </Spin>
    </Modal>
  );
};

export default PostUploadModal;
