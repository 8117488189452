// index of Dashboard code
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import DashboardComponent from './dashboard';
import Notify from '../../components/common/notify';
import { fetchFileMetadata, getAuthenticate } from '../../redux/slice';
const Dashboard = (props) => {
  const { callFetchFileMetadata ,getAuthenticate} = props;
  const [processing, setProcessing] = useState({});
  const [metadata, setMetadata] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isShowConvertPopup, setIsShowConvertPopup] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const authenticate = useSelector(state => state.authenticate)
  const [isListingUpdate, setIsListingUpdate] = useState(false);
  
  useEffect(() => {
    if (!authenticate?.getAuthenticateData) {
      getAuthenticate();
    }
  }, [authenticate?.getAuthenticateData, getAuthenticate]);
  

  // Fetch dropdown List

  const fileMetadata = async (type) => {
    setLoading(true);
    const metadata = await callFetchFileMetadata(type);
    try {
      if (metadata.type === 'files/fetchFileMetadata/fulfilled') {
        setMetadata(metadata.payload);
      } else {
        Notify('error', metadata?.type?.message, '');
      }
    } catch (error) {
      Notify('error', error.message, '');
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardComponent
      onFileMetadata={fileMetadata}
      metadata={metadata}
      isLoading={isLoading}
      selectedFiles={selectedFiles}
      setSelectedFiles={setSelectedFiles}
      setProcessing={setProcessing}
      processing={processing}
      selectedRowKeys={selectedRowKeys}
      setSelectedRowKeys={setSelectedRowKeys}
      onCloseConvertPopUp={setIsShowConvertPopup}
      isShowConvertPopup={isShowConvertPopup}
      setMetadata={setMetadata}
      setIsListingUpdate={setIsListingUpdate}
      isListingUpdate={isListingUpdate}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  callFetchFileMetadata: (data) => dispatch(fetchFileMetadata(data)),
  getAuthenticate: () => dispatch(getAuthenticate())
});

export default connect(null, mapDispatchToProps)(Dashboard);
